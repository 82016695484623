import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FaCheckCircle as CheckIcon } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./feedback.module.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Feedback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
      formQuestions: [
        "For a comprehensive survey, the survey length was appropriate",
        "Completing the survey was easy",
        "I understood why the questions were included",
        "The questions were repetitive",
        "I felt I learned some things whilst completing the survey",
        "All the questions were phrased clearly",
        "Your Network (the org chart) was intuitive",
        "I felt I could be completely honest in my answers",
        "Getting the Preliminary Report immediately after completion was helpful",
        "Finding things I wanted in the report was easy",
        "The report was simple to understand",
        "The comparisons and recommendations provided in the report were insightful",
        "I understood the scores and results like Health Age and DASS",
        "People will change as a result of reading their report and recommendations",
      ],
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(oldState => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.formData,
      }),
    })
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => alert(error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { data } = this.props
    const { submitted, submitting, formQuestions } = this.state

    return (
      <Layout>
        <SEO
          title="Feedback"
          description="Take this short, anonymised feedback survey to let us know about your experience using SHAPE."
        />
        <div styleName="outer-wrapper">
          <Container styleName="container">
            <div styleName="inner-wrapper">
              <div styleName="desc-parent">
                <h1>SHAPE feedback form</h1>
                <p>
                  Thank you for using the SHAPE Service. Please take two minutes
                  to let us know about your experience. Your responses are
                  completely anonymous.
                </p>
              </div>
              <form
                styleName="form-parent"
                name="feedback"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                disabled={submitted}
                id="feedback-form"
              >
                <Row>
                  <Col sm={12}>
                    <div styleName="input-parent">
                      <label>Company name</label>
                      <input
                        type="text"
                        required
                        name="companyName"
                        id="feedback-input-companyname"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div styleName="input-parent">
                      <label>Country</label>
                      <input
                        type="text"
                        required
                        name="countries"
                        id="feedback-input-country"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    {formQuestions.map((question, index) => (
                      <>
                        {index === 0 && (
                          <>
                            <div styleName="section-heading">Survey</div>
                            <div styleName="top-label-bar">
                              <div styleName="left-empty-space"></div>
                              <div styleName="right-labels">
                                <div>Strongly Agree</div>
                                <div>Agree</div>
                                <div>Neutral</div>
                                <div>Disagree</div>
                                <div>Strongly Disagree</div>
                              </div>
                            </div>
                          </>
                        )}
                        {index === 8 && (
                          <>
                            <div styleName="section-heading">Report</div>
                            <div styleName="top-label-bar">
                              <div styleName="left-empty-space"></div>
                              <div styleName="right-labels">
                                <div>Strongly Agree</div>
                                <div>Agree</div>
                                <div>Neutral</div>
                                <div>Disagree</div>
                                <div>Strongly Disagree</div>
                              </div>
                            </div>
                          </>
                        )}
                        <div styleName="input-parent">
                          <label styleName="customHeight">{question}</label>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label className="form-group">
                              <input
                                type="radio"
                                value="Strongly Agree"
                                id={`feedback-radio-${index}-sa`}
                                required
                                styleName="radio-input"
                                name={question}
                                onChange={this.handleChange}
                              />{" "}
                              {/* <span styleName="label-custom">Strongly Agree</span> */}
                            </label>
                            <br />
                            <label className="form-group">
                              <input
                                type="radio"
                                styleName="radio-input"
                                id={`feedback-radio-${index}-a`}
                                required
                                value="Agree"
                                name={question}
                                onChange={this.handleChange}
                              />{" "}
                              {/* <span styleName="label-custom">Agree</span> */}
                            </label>
                            <br />
                            <label className="form-group">
                              <input
                                type="radio"
                                value="Neutral"
                                required
                                id={`feedback-radio-${index}-n`}
                                styleName="radio-input"
                                name={question}
                                onChange={this.handleChange}
                              />{" "}
                              {/* <span styleName="label-custom">Neutral</span> */}
                            </label>
                            <br />
                            <label className="form-group">
                              <input
                                type="radio"
                                value="Disagree"
                                required
                                id={`feedback-radio-${index}-da`}
                                styleName="radio-input"
                                name={question}
                                onChange={this.handleChange}
                              />{" "}
                              {/* <span styleName="label-custom">Disagree</span> */}
                            </label>
                            <br />
                            <label className="form-group">
                              <input
                                type="radio"
                                value="Strongly Disagree"
                                required
                                id={`feedback-radio-${index}-sda`}
                                styleName="radio-input"
                                name={question}
                                onChange={this.handleChange}
                              />{" "}
                              {/* <span styleName="label-custom">
                              Strongly Disagree
                            </span> */}
                            </label>
                          </div>
                        </div>
                      </>
                    ))}
                    {/* Question 17 */}
                    <div styleName="input-scale-parent">
                      <label styleName="custom-scale-height">
                        On a scale of 1 to 10 (1 being the worst and 10 being
                        the best), compared to other surveys I've experienced,
                        I'd rate SHAPE a:
                      </label>
                      <div styleName="input-range-parent">
                        <input
                          type="range"
                          min="1"
                          max="10"
                          step="1"
                          // id="feedback-range-rate"
                          name="rating"
                          id="customRange"
                          class="form-range"
                          onChange={e => {
                            this.handleChange(e)
                            var slider = document.getElementById("customRange")
                            var output = document.getElementById("currentValue")
                            output.innerHTML = slider.value
                          }}
                        />
                        <p id="currentValue" styleName="input-range-value"></p>
                      </div>
                    </div>
                    <div styleName="commentParent">
                      <label>Other comments, observations, or questions</label>
                      <input
                        type="text"
                        id="feedback-input-comments"
                        name="comments"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <div styleName="cta">
                  {submitted && (
                    <div styleName="submittedSuccess">
                      Thank you. We’ll be in touch very shortly.
                    </div>
                  )}
                  <button
                    type="submit"
                    id="feedback-form-submit-button"
                    disabled={submitted || submitting}
                    className="text-white"
                  >
                    {(submitted && "Submitted") ||
                      (submitting && "Submitting...") ||
                      "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allCountryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allPositionJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allIndustryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allHowFindJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allCompanySizeJson {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`
